th {
  color: #1A1E29;
  font-size: 14px;
  text-transform: none;
  opacity: 0.5;
  font-weight: 400;
  border: none;
}

.btn-green {
  background-color: #38A169 !important;
  color: #fff !important;
  font-weight: bold !important;
}

.menu-button {
  background-color: #4285F4;
  color: #ffffff;
  font-weight: 700;
  align-self: baseline;
  font-size: 18px;
  height: 48px;
  min-height: 48px;
  transition: all 0.2s;
  border-radius: 0.375em;
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
}

.menu-button:hover {
  background-color: #72a9ff;
}

.menu-button:active {
  background-color: #4285F4;
}

.menu-button:focus {
  box-shadow: outline;
}

.menu-item {
  font-size: 18px;
  justify-content: center;
}

.menu-list {
  width: 180px;
  min-width: 180px;
}

.heading {
  font-size: 26px !important;
  line-height: 48px !important;
  margin-bottom: 17px !important;
  flex-grow: 1 !important;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.table-data td {
  background-color: #F9FBFC !important;
  height: 48px !important;
  vertical-align: middle !important;
}

.table-data tr {
  height: 55px !important;
}

.table-data th {
  background-color: #F9FBFC !important;
  height: 55px !important;
}

.filters-container {
  display: flex;
  gap: 10px; /* Adjusts space between elements */
  align-items: center; /* Aligns items vertically in the center */
  margin-bottom: 20px;
}

.advanced-container {
  position: relative;
  width: 250px;
}

.advanced-input-div {
  border: 1px solid #E2E8F0; 
  height: 40px;
  width: 175px;
  border-radius: 0.375em; 
  z-index: 1000;
  display: flex; 
  align-items: center; 
  justify-content: flex-start; /* Changed 'left' to 'flex-start' for valid CSS */
  padding-left: 15px;
  cursor: pointer;
}

.advanced-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #E2E8F0;
  border-radius: 0.375em;
  z-index: 1000;
  width: 250px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.advanced-dropdown-item {
  display: flex;
  align-items: center;
  padding-left: 15px;
  height: 45px;
  background-color: transparent; /* Assuming you want the background color to be transparent */
}
.advanced-dropdown-label {
  margin-left: 7px;
}

.input-icon-wrapper {
  position: relative;
  display: inline-block;
}

.input-calendar-icon {
  position: absolute;
  right: 10px; /* Adjust based on your needs */
  top: 50%;
  transform: translateY(-50%);
  color: #ccc; /* Adjust the color as needed */
}

.date-picker {
  min-width: 150px !important;
  min-height: 0 !important;
  padding-right: 30px !important;
}

.input-calendar-icon-close {
  position: absolute;
  right: 10px; /* Adjust based on your needs */
  top: 50%;
  transform: translateY(-50%);
  color: #ccc; /* Adjust the color as needed */  
  cursor: pointer
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}