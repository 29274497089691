
.caseview__compliance-item-box {
    border-radius:  0.5em;
    background-color: #F9FBFC;
    padding: 24px;
    min-height: 26;
    margin: 2;
    margin-bottom: 1;
}

.caseview__compliance-item-box-open {
    border-radius:  0.5em;
    background-color: #F9FBFC;
    padding: 24px;
    min-height: 26;
    margin: 2;
    margin-bottom: 1;
    border-bottom: 1px solid #eee;
    border-radius: 0.5em 0.5em 0 0;
}

.caseview__compliance-item-view-button {
    background-color: #ffffff !important;
    border: 1px solid #6E7997;
    color: #6E7997;
    font-size: 16px !important;
    font-weight: 500 !important;
    width: 100%;
    min-height: 55px;
}

.caseview__compliance-item-view-button-upload-new {
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem !important;
    border: 1px solid #6E7997;
    margin-top: 5px !important;
    margin-right: 10px;
    position: relative;
    min-height: 55px;
    background-color: white;    
}

.caseview__compliance-item-view-button-upload-signed {
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem !important;
    border: 1px dashed #6E7997;
    margin-top: 5px !important;
    margin-left: 10px;
    position: relative;
    min-height: 55px;
    background-color: white;    
}

.caseview__compliance-item-view-button-upload-bypass {
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem !important;
    border: 1px dashed #6E7997;
    margin-top: 5px !important;
    margin-left: 10px;
    position: relative;
    min-height: 55px;
    background-color: white;    
}

.caseview__compliance-item-textarea {
    width: 100%; 
    border: 1px solid #cccccc;
    margin-top: 20px;
    padding: 5px;
}



.individuals__menu-icon {
    width: 14.06px;
    height: 14.06px;
    margin-right:10px;
    margin-top: 2px;
    float: left;
}
.individuals__delete-icon {
    width: 13.5px;
    height: 14.06px;
    margin-right:10px;
    margin-top: 2px;
    float: left;
}
.individuals_menu-hr1 {
    margin-top: 8px;
    margin-bottom: 8px;
}
.individuals_menu-hr2 {
    margin-top: 8px;
    margin-bottom: 8px;
}


.dropdown-menu {
    --bs-dropdown-min-width: 25px !important;
    --bs-dropdown-link-active-bg: none !important;
}

.individuals__cell-loading {
    text-align: center !important;
}

.button__blue {
    background-color: #3a78f2 !important;
    color: #fff;
    font-weight: normal !important;
}
.button__blue:hover {
    background-color: #1052d3 !important;
}
.individuals_footer-button {
    margin-top: 10px;
    margin-bottom: 10px;
}

.button__white {
    background-color: #fff !important;
    color: #6E7997;
    font-weight: normal !important;
    border: 1px solid #6E7997;
    font-family: var(--chakra-fonts-body) !important;
    font-weight: 500 !important;
    font-size: 14px !important;
}
.button__white:hover {
    background-color: #e2e8f0 !important;
}
